.card {
  &-img-top {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  &-body {
    min-height: 150px;
  }

  &-footer {
    background-color: inherit;
    border: none;
  }

  margin-bottom: 2 * $spacer;
}

// override buttons so they have white text
.btn-primary {
  @include button-variant($primary, $primary, $color: #fff);
}

.btn-info {
  @include button-variant($info, $info, $color: #fff);
}

.force-left {
  text-align: left !important;
}

.home-image {
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.fixedElement {
  position: fixed;
  top: 120px;
  right: 30px;
  z-index: 100;
}

.content-page {
  margin-top: 120px;
}

.font-bold {
  /* in case Bootstrap says no */
  font-weight: bold;
}

#root .container {
  max-width: 1600px;
}

.dropdown-content {
  background-color: #fff;
}